import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';

// CSS
import './index.css';
import { AuthProvider } from './Hooks/useAuth';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


const theme = extendTheme({
  colors: {
    perla: {
      "50": "#E9F9FC",
      "100": "#C0EEF6",
      "200": "#98E4F1",
      "300": "#70D9EB",
      "400": "#48CEE5",
      "500": "#1EBCD7",
      "600": "#199DB3",
      "700": "#137586",
      "800": "#0C4E5A",
      "900": "#06272D"
    },
  }
  // fonts: {
  //   body: "BabyEliot, Georgia",
  //   heading: "BabyEliot, Georgia",
  // },
})

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
          <Router>
            <AuthProvider>
              <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_SITE_KEY ?? ""}>
                <App />
              </GoogleReCaptchaProvider>
            </AuthProvider>
          </Router>
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
