import { 
  Button, 
  HStack, 
  Spinner, 
  Stack, 
  Tag, 
  useToast, 
  Text, 
  useDisclosure, 
  Box, 
  VStack 
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DataTable, IndeterminateCheckbox } from '../../../Components/DataTable';
import { ColumnDef, Row } from "@tanstack/react-table";
import useAuth from '../../../Hooks/useAuth';
import qs from 'qs';
import { User } from '../../../ExternalTypes';
import { UserAddModal } from './UserAddModal';
import { UserDelDialog } from './UserDelDialog';
import { UserValues } from '../../../Components/UserForm';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function UsersTabPanel() {

  const { token, user } = useAuth();
  const strapiHeaders = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  const [reCaptchaToken, setReCaptchaToken] = useState<string>();
  const queryClient = useQueryClient();
  const toast = useToast({
    position: 'top',
    containerStyle: {
      minWidth: '480px',
      maxWidth: '100%',
    },
  });
  
  const { isLoading: isStrapiUsersLoading, isError: isStrapiUsersError, data: strapiUsers } = useQuery(
    ["strapi", "users"], 
    async () => {
      const query = qs.stringify({
        sort: ['name:asc'],
        populate: '*'
      }, {
        encodeValuesOnly: true, // prettify URL
      });
      return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users?${query}`, 
        {
          headers: strapiHeaders
        })
        .then((response) => {
          return response.data as User[];
        });
  });

  const { mutateAsync: addUser, isLoading: isUserAdding, status: addStatus } = useMutation(
    async (values: UserValues) => await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/auth/local/register`, 
        {
          name: values.name,
          username: values.email,
          email: values.email,
          password: values.password,
          token: reCaptchaToken,
        }, 
        {
          headers: strapiHeaders
        }
      )
      .then(response => {
        queryClient.invalidateQueries(["strapi", "users"], {
          refetchActive: true,
        });
        onUserAddModalClose();
        toast({
          title: 'CLIENTI',
          description: `Cliente creato con successo!`,
          status: 'success',
          duration: 4000,
          isClosable: true,
        });

        setReCaptchaToken(undefined);
      })
      .catch(error => {
        toast({
          title: 'CLIENTI',
          description: 
            <VStack pt={4} spacing={1} alignItems="baseline" justifyContent="flex-start">
              <Text>Creazione cliente fallita!</Text>
              <Text fontStyle="italic">{error.response.data.error.message}</Text>
            </VStack>,
          status: 'error',
          duration: 6000,
          isClosable: true,
        });

        setReCaptchaToken(undefined);
      })
  );
  
  const { mutateAsync: delUser, isLoading: isUserDeleting } = useMutation(
    async () => {
      Object.keys(rowSelection).map(userId => 
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/users/${userId}`, 
          {
            headers: strapiHeaders
          }
        )
        .then(response => {
          queryClient.invalidateQueries(["strapi", "users"], {
            refetchActive: true,
          });
          onUserDelModalClose();
          setRowSelection({});
          console.log('Success:', response);
          toast({
            title: 'CLIENTI',
            description: 
              <VStack pt={4} spacing={1} alignItems="baseline" justifyContent="flex-start">
                <Text>Cliente rimosso con successo!</Text>
              </VStack>,
            status: 'success',
            duration: 4000,
            isClosable: true,
          });
        })
        .catch(error => {
          console.log('An error occurred:', error.response);
          toast({
            title: 'CLIENTI',
            description: 
              <VStack pt={4} spacing={1} alignItems="baseline" justifyContent="flex-start">
                <Text>Rimozione cliente fallita!</Text>
                <Text fontStyle="italic">{error.response.data.error.message}</Text>
              </VStack>,
            status: 'error',
            duration: 6000,
            isClosable: true,
          });
        })
      );
    }
  );

  const { executeRecaptcha } = useGoogleReCaptcha();
  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const value = await executeRecaptcha('Register');
    setReCaptchaToken(value);
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify, addStatus]);  

  const [rowSelection, setRowSelection] = React.useState({})

  const columns = React.useMemo<ColumnDef<User>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <Box>
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </Box>
        ),
      },
      {
        accessorKey: 'id',
        cell: (info) => info.getValue(),
        header: "Id"
      },
      {
        accessorKey: 'name',
        cell: (info) => info.getValue(),
        header: "Nome"
      },
      {
        accessorKey: 'username',
        cell: (info) => info.getValue(),
        header: "Username"
      },
      {
        accessorKey: 'email',
        cell: (info) => info.getValue(),
        header: "Email"
      },      {
        accessorKey: 'confirmed',
        cell: ({row: {
          original: { confirmed }
        }}) => confirmed ? <Tag colorScheme="green">CONFERMATO</Tag> : <Tag colorScheme="yellow">IN ATTESA</Tag>,
        header: "Confermato"
      },
      {
        accessorKey: 'role',
        cell: ({row: {
          original: { role }
        }}) => <Tag colorScheme="gray">{role.name}</Tag>,
        header: "Ruolo"
      },
      {
        accessorKey: 'Order.ProductName',
        cell: ({row: {
          original: { Order }
        }}) => (Order ? Order.ProductName : "Nessuno"),
        header: "Abbonamento"
      },
      {
        accessorKey: 'Order.ProductId',
        cell: ({row: {
          original: { Order }
        }}) => (Order ? Order.ProductId : "N/A"),
        header: "Product id"
      }
    ],
    []
  );

  const {
    isOpen: isUserAddModalOpen,
    onOpen: onUserAddModalOpen,
    onClose: onUserAddModalClose,
  } = useDisclosure();

  const {
    isOpen: isUserDelModalOpen,
    onOpen: onUserDelModalOpen,
    onClose: onUserDelModalClose,
  } = useDisclosure();
  const cancelRef = React.useRef()

  const getUserRowId = (
      row: User, 
      relativeIndex: number, 
      parent: Row<User> | undefined) => row.id

  return isStrapiUsersLoading ? <Spinner size="md"/> : 
  (
    <Stack minH={500} spacing={4} my={6} >
      <>
        <HStack>
          <Button
            bgColor="perla.600"
            color="white"
            _hover={{
              bgColor: "perla.500"
            }}
            onClick={onUserAddModalOpen}
          >
            <Text>Aggiungi utente</Text>
          </Button>
          <Button
            bgColor="red.600"
            color="white"
            _hover={{
              bgColor: "red.500"
            }}
            onClick={onUserDelModalOpen}
            isDisabled={Object.keys(rowSelection).length == 0 || Object.keys(rowSelection).some(k => k == user.id)}
          >
            <Text>Rimuovi utente</Text>
          </Button>
        </HStack>

        <UserAddModal 
          isOpen={isUserAddModalOpen}
          onClose={onUserAddModalClose}
          isMutateLoading={isUserAdding}
          onCreate={addUser}
        />

        <UserDelDialog 
          isOpen={isUserDelModalOpen}
          onClose={onUserDelModalClose}
          cancelRef={cancelRef}
          onDelete={delUser}
        />
      </>

      <DataTable<User> 
        columns={columns} 
        data={strapiUsers!} 
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        getRowId={getUserRowId}
      />

    </Stack>
  );
}