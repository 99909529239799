import React, { useCallback, useEffect, useState } from 'react';
import { Stack, VStack, Text, Box } from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { UserForm, UserValues } from '../Components/UserForm';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function RegisterPage() {
  
  const [reCaptchaToken, setReCaptchaToken] = useState<string>();
  const queryClient = useQueryClient();
  const toast = useToast({
    position: 'top',
    containerStyle: {
      minWidth: '480px',
      maxWidth: '100%',
    },
  });

  const strapiHeaders = {
    'Content-Type': 'application/json',
  };

  const { mutateAsync: registerUser, isLoading: isRegistering, status: registerStatus } = useMutation(
    async (values: UserValues) => await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/auth/local/register`, 
        {
          name: values.name,
          username: values.email,
          email: values.email,
          password: values.password,
          token: reCaptchaToken,
        },
        {
          headers: strapiHeaders
        },
    )
    .then(response => {
      queryClient.invalidateQueries(["strapi", "users"], {
        refetchActive: true,
      });
      toast({
        title: 'REGISTRAZIONE',
        description: 
          <VStack pt={4} spacing={1} alignItems="center" justifyContent="center">
            <Text>Nuovo utente registrato con successo!</Text>
            <Text>Ti abbiamo inviato una mail con il link per confermare il tuo account.</Text>
          </VStack>,
        status: 'success',
        duration: 6000,
        isClosable: true,
      });

      setReCaptchaToken(undefined);
    })
    .catch(error => {
      toast({
        title: 'REGISTRAZIONE',
        description: 
          <VStack pt={4} spacing={1} alignItems="center" justifyContent="center">
            <Text>Registrazione utente fallita!</Text>
            <Text fontStyle="italic">{error.response.data.error.message}</Text>
          </VStack>,
        status: 'error',
        duration: 6000,
        isClosable: true,
      });

      setReCaptchaToken(undefined);
    })
  );

  const { executeRecaptcha } = useGoogleReCaptcha();
  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const value = await executeRecaptcha('Register');
    setReCaptchaToken(value);
  }, [executeRecaptcha, registerStatus]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);  
  
  return (
    <Stack py={10}>
      <Box 
        alignSelf="center"
        w={["90%", "90%", "80%", "60%", "50%"]}
        borderWidth={2}
        borderRadius={10}
        boxShadow="md"
        bgColor="white"
      >
        <UserForm 
          onSubmit={registerUser}
          title="Registrati"
        />
      </Box>
    </Stack>
  );
}