import { Box, Divider, Flex, HStack, Icon, Stack, Text, VStack, Image, SimpleGrid, Link, textDecoration } from '@chakra-ui/react';
import React from 'react';
import { FaRegCopyright } from 'react-icons/fa';
import { MdOutlineEmail, MdOutlinePhone } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import SocialBar from './Components/SocialBar';
import { menuPublic } from './Header';
import Logo from './Logo.webp';

export default function Footer() {
  return (
    <Stack 
      backgroundColor="perla.800"
      alignItems="center"
      py={4}
    >
      <SimpleGrid 
        pt={2} pb={6} px={6} 
        columns={[1, 1, 3, 3]}
        alignItems="center"
        gap={4}
      >
        <VStack
          alignItems="baseline" 
          spacing={4}
          pl={6}
        >
          <Box>
            <Text 
              color="perla.500" 
              fontSize={["14", "16", "18", "20", "20"]}
              fontWeight="bold"
              mb={2}
            >
              Obiettivo
            </Text>
            <Text color="white" fontSize={["12", "12", "14", "14", "14"]}>
              Perlalis desidera diffondere la lingua dei segni italiana attraverso la ricerca di un lessico che può essere utilizzato nella vita quotidiana attraverso semplici conversazioni. 
              L’intento è quello di andare anche oltre le proprietà linguistiche e di far conoscere le caratteristiche culturali della comunità sorda.
            </Text>
          </Box>
          <SocialBar 
            fontSize={["30", "32", "32", "36", "40"]}
          />
        </VStack>

        <VStack spacing={3}>
          <Image
            src={Logo} 
            alt="Perlalis"
            height="auto"
            width="60px"
          />

          <HStack justifyContent="flex-start" alignItems="center">
            <Icon as={MdOutlineEmail} w="30px" h="30px" color="white"/>
            <Link 
              href='mailto:info@perlalis.it'
              color="teal"
            >
              <Text 
                color="white" 
                fontSize={["20", "22", "22", "24", "24"]}
                mb={1}
              >info@perlalis.it</Text>
            </Link>
          </HStack>
          <HStack justifyContent="center" alignItems="center">
            <Icon as={MdOutlinePhone} w="30px" h="30px" color="white"/>
            <Link 
              href='tel:+39 3470447422'
              color="teal"
            >
              <Text 
                color="white" 
                fontSize={["20", "22", "22", "24", "24"]}
                mb={1}
              >+39 3470447422</Text>
            </Link>
          </HStack>

          {/* <HStack
            color="white" 
            textAlign="center"
            spacing={[4, 4, 6, 6, 8]}
          >
            {menuPublic.map((item) => {
              return (
                <Link 
                  key={`menu-${item.id}`}
                  as={NavLink}
                  to={item.to} 
                  _hover={{
                    color: "perla.400",
                  }}
                  _activeLink={{
                    color: "perla.500",
                  }}
                >
                    <Text textAlign="center">{item.name.toUpperCase()}</Text>
                </Link>
              );
            })}
          </HStack> */}
        </VStack>

        <VStack
          color="white" 
          textAlign="center"
          spacing={[4, 4, 5, 5, 6]}
          fontSize={["16", "18", "18", "20", "20"]}
          pt={[6, 6, 0, 0]}
        >
          <Link 
            as={NavLink} 
            to="/condizioni-uso"
            _hover={{
              color: "perla.300"
            }}
            _activeLink={{
              color: "perla.500",
            }}
          >
            <Text>
              CONDIZIONI D'USO
            </Text>
            <Divider color="perla.300"/>
          </Link>
          <Link 
            as={NavLink} 
            to="/informativa-privacy"
            _hover={{
              color: "perla.300"
            }}
            _activeLink={{
              color: "perla.500",
            }}
          >
            <Text>
              INFORMATIVA SULLA PRIVACY
            </Text>
            <Divider color="perla.300"/>
          </Link>
          <Link 
            as={NavLink} 
            to="/cookie-policy"
            _hover={{
              color: "perla.300"
            }}
            _activeLink={{
              color: "perla.500",
            }}
          >
            <Text>
              COOKIE POLICY
            </Text>
            <Divider color="perla.300"/>
          </Link>
        </VStack>



      </SimpleGrid>

      <Stack 
        w="95%" 
        bgColor="perla.800" 
        alignItems="center"
        pt={4}
        borderTopWidth={1}
        borderTopColor="perla.200"
      >
        <Flex>
          <Icon as={FaRegCopyright} color="white" mt={1} mr={1}/><Text color="white">2023 Perlalis di Calogero Petrucci - P.IVA 02347920684</Text>
        </Flex>
      </Stack>

    </Stack>
  );
}