import { 
  Stack, 
  SimpleGrid, 
  Button, 
  Spinner, 
  Tag, 
  Text, 
  HStack, 
  VStack, 
  Box, 
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Badge,
  Divider,
  Link,
  Icon,
  IconButton,
  ButtonGroup
} from '@chakra-ui/react';
import axios from 'axios';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import useAuth from '../../Hooks/useAuth';
import { 
  StrapiCategoryResponse, 
  StrapiOrderResponse, 
  StrapiVideoResponse, 
  StripeSubscription, 
  StripeSubscriptionStatus 
} from '../../ExternalTypes';
import VideoBox from '../../Components/VideoBox';
import { FaCreditCard, FaExternalLinkAlt, FaInfinity } from 'react-icons/fa';
import CountUp from 'react-countup';
import { 
  Pagination, 
  PaginationContainer, 
  PaginationNext, 
  PaginationPage, 
  PaginationPageGroup, 
  PaginationPrevious, 
  PaginationSeparator, 
  usePagination 
} from '@ajna/pagination';
import { getPlanType, PlanType } from '../../Utils/subscriptionUtils';
import { RiAdminFill } from 'react-icons/ri';
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

export default function VideoPage() {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const { token, user } = useAuth();
  const strapiHeaders = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  const qs = require('qs');
  
  const [categorySelected, setCategorySelected] = useState<string>("");
  const [videosTotal, setVideosTotal] = useState<number | undefined>(undefined);

  // constants
  const outerLimit = 2;
  const innerLimit = 1;
  
  // pagination hook
  const {
    pages,
    pagesCount,
    offset,
    currentPage,
    setCurrentPage,
    setIsDisabled,
    isDisabled,
    pageSize,
    setPageSize,
  } = usePagination({
    total: videosTotal,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: 12,
      isDisabled: false,
      currentPage: 1,
    },
  });

  const { 
    isLoading: isCategoriesLoading, 
    data: categories, 
    isError: isCategoriesError, 
    isSuccess: isCategoriesSuccess } = useQuery("categories", 
    async () => {
      const query = qs.stringify({
        sort: ['Name'],
        populate: '*',
        pagination: {
          page: 1,
          pageSize: 100,
        },
      }, {
        encodeValuesOnly: true, // prettify URL
      });
      return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/categories?${query}`, 
        {
          headers: strapiHeaders
        })
        .then((response) => {
          return response.data;
        });
    }
  );

  const { 
    isLoading: isVideosLoading, 
    isError: isVideosError, 
    isSuccess: isVideosSuccess,
    data: videos } = useQuery("videos", 
    async () => {
      return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/videos`, 
        {
          headers: strapiHeaders
        })
        .then((response) => {
          return response.data;
        });
  });

  const { 
    isLoading: isVideosByCategoryLoading, 
    isSuccess: isVideosByCategorySuccess,
    data: videosByCategory } = useQuery(
    ["videos", categorySelected, pageSize, currentPage], 
    async () => {
      const query = qs.stringify({
        sort: ['Name'],
        filters: categorySelected != "" 
          ? {
              VimeoFolderId: {
                $eq: categorySelected,
              }
            }
          : [],
        populate: '*',
        pagination: {
          page: currentPage,
          pageSize: pageSize,
        },
      }, {
        encodeValuesOnly: true, // prettify URL
      });

      return await axios.get( 
        `${process.env.REACT_APP_BACKEND_URL}/api/videos?${query}`,
        {
          headers: strapiHeaders
        })
        .then((response) => {
          setVideosTotal(response.data.meta.pagination.total);
          return response.data;
        });
    }
  );

  const { isLoading: isOrderLoading, data: order } = useQuery(
    ["orders", user.id], 
    async () => {
      const query = qs.stringify({
        sort: ['createdAt:asc'],
        filters: {
          User: {
            id: {
              $eq: user.id,
            }
          },
        },
        populate: '*'
      }, {
        encodeValuesOnly: true, // prettify URL
      });

      return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/orders?${query}`, 
        {
          headers: strapiHeaders
        })
        .then((response) => {
          return response.data.data[0] ? response.data.data[0] as StrapiOrderResponse : null;
        });
    }
  );

  const { isLoading: isSubscriptionLoading, data: subscription } = useQuery(
  ["orders", "getSubscription", order?.attributes.SubscriptionId], 
    async () => {
      return (!order || order == null) ? null : 
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/orders/getSubscription`,
          {
            data: { subscriptionId: order?.attributes.SubscriptionId }
          }, 
          {
            headers: strapiHeaders
          },
          )
          .then((response) => {
            return response.data as StripeSubscription;
          });
  });

  const { isLoading: isVideosEnabledByUserLoading, data: videosEnabledByUser } = useQuery(
    ["videos-enabled", user.id], 
    async () => {
      const query = qs.stringify({
        sort: ['createdAt:asc'],
        filters: {
          User: {
            id: {
              $eq: user.id,
            }
          }
        },
        populate: '*'
      }, {
        encodeValuesOnly: true, // prettify URL
      });

      return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/videos-enabled?${query}`, 
      {
        headers: strapiHeaders
      })
      .then((response) => {
        return response.data ? response.data : null;
      });
  });

  // handlers
  const handlePageChange = (nextPage: number): void => {
    // -> request new data using the page number
    setCurrentPage(nextPage);
  };

  const handlePageSizeChange = (
    event: ChangeEvent<HTMLSelectElement>
  ): void => {
    const pageSize = Number(event.target.value);

    setPageSize(pageSize);
  };



  const getSubscriptionStatusBadge = (status?: string, cancelAt?: number) => {
    
    // if (cancelAt && cancelAt != null)
    // {
    //   return <Badge colorScheme="yellow">Annullato</Badge>;
    // }

    switch(status)
    {
      case StripeSubscriptionStatus.Active:
        return <Badge colorScheme="green">ATTIVO</Badge>;
      case StripeSubscriptionStatus.Canceled:
        return <Badge colorScheme="red">CANCELLATO</Badge>;
      default:
        return <Badge colorScheme="gray">{status}</Badge>;
    }
  }

  const getSubscriptionTagColor = (plan: PlanType) => {
    switch(plan)
    {
      case PlanType.Bronze:
        return "orange.600";
      case PlanType.Silver:
        return "gray";
      case PlanType.Gold:
        return "goldenrod";
      case PlanType.None:
        return "gray";
    }
  }

  console.log({subscription});
  const planType = getPlanType(subscription?.plan?.product);
  const subscriptionTagColor = planType ? getSubscriptionTagColor(planType) : "gray";

  return (isCategoriesLoading ? 
    <Spinner 
      size="lg" 
      alignSelf="center"
      thickness='4px'
      speed='0.65s'
      emptyColor='gray.200'
      color='perla.500'
    /> :
    (
      <Stack minH={400} pt={6} pb={20}>

        {/* INFO */}
        <SimpleGrid 
          columns={[1, 1, 2, 2]}
          bgColor="white" 
          mx={10} 
          borderWidth={2}
          borderRadius={10}
          boxShadow="md"
        >
          {/* INFO VIDEOS */}
          <Box 
            py={4}
            px={[4, 4, 6, 8, 10]}
          >
            <SimpleGrid 
              columns={2} 
              gap={[4, 6, 8, 10]} 
              spacing={[4, 6, 8, 10]}
              width="100%"
              justifyItems="center"
            >
              <Box
                backgroundColor="perla.500"
                color="white"
                h={[90, 100, 120, 140]}
                w={[90, 100, 120, 140]}
                borderRadius={100}
                textAlign="center"
              >
              {isVideosLoading ? 
                <Stack
                  spacing={0}
                  h="100%" 
                  w="100%"
                  justifyContent="center"
                >
                  <Spinner 
                    size="lg" 
                    alignSelf="center"
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color="white"
                  />
                </Stack> :
                <CountUp 
                  start={0} 
                  end={videos ? videos.meta.pagination.total : 0}
                  delay={0}
                >
                  {({ countUpRef, start }) => (
                    <Stack
                      spacing={0}
                      h="100%" 
                      w="100%" 
                      fontFamily="Calibri" 
                      fontSize={[20, 30, 40]} 
                      fontWeight="bold"
                      justifyContent="center"
                      onClick={start}
                      onMouseEnter={start}
                    >
                      <span ref={countUpRef} />
                      <Text
                        fontFamily="Calibri" 
                        fontSize={[16, 18, 22, 24, 26]} 
                        fontWeight="normal"
                        fontStyle="italic"
                      >Video</Text>
                    </Stack>
                  )}
                </CountUp>
              }
              </Box>
              <Box
                backgroundColor="perla.500"
                color="white"
                h={[90, 100, 120, 140]}
                w={[90, 100, 120, 140]}
                borderRadius={100}
                textAlign="center"
              >
              {isCategoriesLoading ? 
                <Stack
                  spacing={0}
                  h="100%" 
                  w="100%"
                  justifyContent="center"
                >
                  <Spinner 
                    size="lg" 
                    alignSelf="center"
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color="white"
                  />
                </Stack> :
                <CountUp 
                  start={0} 
                  end={categories ? categories.meta.pagination.total : 0}
                  delay={0}
                >
                  {({ countUpRef, start }) => (
                    <Stack
                      spacing={0}
                      h="100%" 
                      w="100%" 
                      fontFamily="Calibri" 
                      fontSize={[20, 30, 40]} 
                      fontWeight="bold"
                      justifyContent="center"
                      onClick={start}
                      onMouseEnter={start}
                    >
                      <span ref={countUpRef} />
                      <Text
                        fontFamily="Calibri" 
                        fontSize={[16, 18, 22, 24, 26]} 
                        fontWeight="normal"
                        fontStyle="italic"
                      >Categorie</Text>
                    </Stack>
                  )}
                </CountUp>
              }
              </Box>
            </SimpleGrid>
          </Box>

          {/* INFO ABBONAMENTO */}
          <VStack 
            py={4}
            pr={[10, 2, 4, 4, 10]}
            pl={[10, 2, 10, 50, 60]}
            spacing={[1, 2, 3]}
            alignItems="self-start"
          >
            <SimpleGrid 
              columns={2} 
              // alignSelf="stretch" 
              gap={2}
              w="100%"
            >
              <Text
                fontSize={["14", "16", "20", "24", "28"]}
                fontFamily="Calibri"
                fontWeight="bold"
                minWidth="fit-content"
              >
                Abbonamento: 
              </Text>
              <Stack flexDirection="row" justifyContent="end">
                {!subscription || user.role.name === "Administrator"
                  ? 
                    <Tag 
                      alignSelf="center"
                      w="min-content"
                      minW="fit-content"
                      bgColor={user.role.name === "Administrator" ? "perla.500" : subscriptionTagColor}
                      color="white" 
                      fontSize={["10", "10", "12", "16", "20"]}
                      fontFamily="Calibri"
                      fontWeight="bold"
                      borderRadius={16}
                      px={4}
                      py={1}
                    >
                      {user.role.name === "Administrator" 
                        ? <>{"ADMIN"} <Icon as={RiAdminFill} ml={2}/></> 
                        : isSubscriptionLoading 
                          ? <Spinner/> 
                          : planType.toUpperCase()
                      }
                    </Tag>
                  : 
                    <Popover>
                      <ButtonGroup 
                        isAttached 
                        variant='solid'
                        color="white"
                        borderRadius={16}
                      >
                        <Button 
                          bgColor={subscriptionTagColor}
                          fontSize={["10", "10", "12", "16", "20"]}
                          fontFamily="Calibri"
                          fontWeight="bold"
                          _hover={{
                            bgColor: subscriptionTagColor,
                            cursor: "default"
                          }}
                        >
                          {planType.toUpperCase()}
                        </Button>
                        <PopoverTrigger>
                          {isSubscriptionLoading ? <Spinner/> :
                            <IconButton 
                              bgColor="gray.100"
                              aria-label='Clicca per vedere i dettagli del tuo abbonamento' 
                              icon={<FaCreditCard />} 
                              textColor="perla.500"
                              _hover={{
                                bgColor: "perla.700",
                                textColor: "white"
                              }}  
                            />
                          }
                        </PopoverTrigger>
                      </ButtonGroup>
                      <PopoverContent borderWidth={2} w={[410, 420, 500, 600]} mr={4}>
                        <PopoverArrow />
                        <PopoverCloseButton fontSize={14} mt={1} mr={2}/>
                        <PopoverHeader fontWeight="bold" fontSize={18} color="perla.600">Dettagli abbonamento:</PopoverHeader>
                        <PopoverBody py={4}>
                          <HStack>
                            <Text fontWeight="bold">Stato:</Text>
                            {getSubscriptionStatusBadge(subscription?.status, subscription?.cancel_at)}
                          </HStack>
                          {/* {subscription?.cancel_at && subscription?.cancel_at != null 
                            ?
                            <HStack pb={2}>
                              <Text fontStyle="italic">
                                Il tuo piano è ancora attivo ma sarà annullato il giorno <Tag colorScheme="gray">{(new Date((subscription?.cancel_at ?? 0) * 1000)).toLocaleString()}</Tag>
                              </Text>
                            </HStack>
                            : null
                          } */}
                          <HStack>
                            <Text fontWeight="bold">Data di attivazione :</Text>
                            <Text>{(new Date((subscription?.start_date ?? 0) * 1000)).toLocaleString()}</Text>
                          </HStack>
                          {subscription?.cancel_at && subscription?.cancel_at != null 
                            ?
                              <HStack>
                                <Text fontWeight="bold">Data di scadenza :</Text>
                                <Text>{(new Date((subscription?.cancel_at ?? 0) * 1000)).toLocaleString()}</Text>
                              </HStack>
                            : null
                          }
                          {!subscription?.cancel_at || subscription?.cancel_at == null
                            ?
                              <HStack>
                                <Text fontWeight="bold">Data prossimo pagamento:</Text>
                                <Text>{(new Date((subscription?.current_period_end ?? 0) * 1000)).toLocaleString()}</Text>
                            </HStack>
                            : null
                          }
                          <HStack>
                            <Text fontWeight="bold">Pagamento:</Text>
                            <Text>{subscription!.plan!.amount / 100} € / mese</Text>
                          </HStack>
                          <Divider orientation='horizontal' my={2}/>
                          <HStack>
                            <Link 
                              href={`https://billing.stripe.com/p/login/test_fZebLtdzv9012dO144?prefilled_email=${encodeURIComponent(user.email)}`} 
                              isExternal 
                              color="perla.600">
                              Accedi al portale cliente <Icon as={FaExternalLinkAlt} mx='2px' />
                            </Link>
                          </HStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                }
              </Stack>
            </SimpleGrid>

            <SimpleGrid 
              columns={2} 
              // alignSelf="stretch" 
              gap={2}
              w="100%"
            >
              <Text
                fontSize={["14", "16", "20", "24", "28"]}
                fontFamily="Calibri"
                fontWeight="bold"
                minWidth="fit-content"
              >
                Video disponibili: 
              </Text>
              <Stack flexDirection="row" justifyContent="end">
                <Tag 
                  alignSelf="center"
                  w="max-content"
                  bgColor="orange.500"
                  color="white" 
                  fontSize={["10", "10", "12", "16", "20"]}
                  fontFamily="Calibri"
                  fontWeight="bold"
                  borderRadius={16}
                  px={4}
                  py={1}
                >
                  {user.role.name === "Administrator" 
                    ? isVideosLoading 
                      ? <Spinner/> 
                      : <>{videos.meta.pagination.total} <Icon as={FaInfinity} ml={2}/></>
                    : isOrderLoading 
                      ? <Spinner/> 
                      : order?.attributes?.MaxVideos ?? 0
                  }
                </Tag> 
              </Stack>
            </SimpleGrid>

            <SimpleGrid 
              columns={2} 
              gap={2}
              w="100%"
            >
              <Text
                fontSize={["14", "16", "20", "24", "28"]}
                fontFamily="Calibri"
                fontWeight="bold"
                minWidth="fit-content"
              >
                Video attivati: 
              </Text>
              <Stack flexDirection="row" justifyContent="end">
                <Tag 
                  alignSelf="center"
                  w="min-content"
                  bgColor="perla.500"
                  color="white" 
                  fontSize={["10", "10", "12", "16", "20"]}
                  fontFamily="Calibri"
                  fontWeight="bold"
                  borderRadius={16}
                  px={4}
                  py={1}
                >
                  {isVideosEnabledByUserLoading ? <Spinner/> : videosEnabledByUser?.meta?.pagination?.total ?? "N/A"}
                </Tag> 
              </Stack>
            </SimpleGrid>
          </VStack>

        </SimpleGrid>

        {/* MENU CATEGORIE */}
        {isCategoriesSuccess && 
          <Stack
            px={10}
            py={6}
          >
            <Button 
              borderRadius={6} 
              size="lg"
              bgColor={categorySelected == "" ? "perla.500" : "perla.700"}
              color="white"
              _hover={{
                backgroundColor: "orange.500"
              }}
              onClick={() => setCategorySelected("")}
            >Tutte</Button>

            <SimpleGrid
              columns={[2, 2, 3, 5, 7]}
              minChildWidth={[130, 150, 160, 175, 200]}
              spacing={2}

            >
              {categories.data.map((c: StrapiCategoryResponse) => {
                return (
                  <Button 
                    key={`btn-${c.id}`}
                    size="auto"
                    borderRadius={6} 
                    bgColor={categorySelected == c.attributes.VimeoFolderId ? "perla.500" : "perla.700"}
                    color="white"
                    _hover={{
                      backgroundColor: "orange.500"
                    }}
                    fontSize={["12", "12", "14", "14", "16"]}
                    onClick={() => setCategorySelected(c.attributes.VimeoFolderId)}
                    py={2}
                  >
                    <HStack>
                      <Text>{c.attributes.Name}</Text>
                      <Tag bgColor="perla.200" borderRadius={10}>
                          <Text 
                            fontSize={["10", "10", "12", "12", "14"]}
                          >
                            {c.attributes.Videos.data.length}
                          </Text>
                      </Tag>
                    </HStack>
                  </Button>
                );
              })}
            </SimpleGrid> 
          </Stack>
        }

        {isVideosByCategoryLoading || isVideosEnabledByUserLoading || isOrderLoading ? 
          <Spinner 
            size="lg" 
            alignSelf="center"
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='perla.500'
          /> :
          <>
            <Stack>
              <HStack px={12} spacing={3}>
                <Text 
                  color="gray.600"
                  fontSize={["22", "24", "26", "28", "28"]}
                >Video trovati: </Text> 
                <Text 
                  fontWeight="bold" 
                  color="perla.500"
                  fontSize={["22", "24", "26", "28", "28"]}
                >{videosByCategory ? videosByCategory.meta.pagination.total : 0}</Text>
              </HStack>
            </Stack>

            {isVideosByCategorySuccess &&
              <Stack py={2} w="90%" alignSelf="center">
                <Pagination
                  pagesCount={pagesCount}
                  currentPage={currentPage}
                  isDisabled={isDisabled}
                  onPageChange={handlePageChange}
                >
                  <PaginationContainer
                    align="center"
                    justify="space-between"
                    px={4}
                    w="full"
                  >
                    <PaginationPrevious
                      _hover={{
                        bg: "orange.500",
                      }}
                      bg="perla.700"
                      color="white"
                      isDisabled={currentPage == 1}
                    >
                      {windowSize.innerWidth < 720 
                        ? <Icon as={MdOutlineArrowBackIosNew}/>
                        : <Text>Indietro</Text>
                      }
                    </PaginationPrevious>

                    <PaginationPageGroup
                      isInline
                      align="center"
                      separator={
                        <PaginationSeparator
                          isDisabled
                          bg="gray.800"
                          fontSize="md"
                          color="white"
                          w={[6, 6, 10, 10]}
                          jumpSize={11}
                        />
                      }
                    >
                      {pages.map((page: number) => (
                        <PaginationPage
                          w={[8, 8 , 12, 12]}
                          bg="perla.700"
                          key={`pagination_page_${page}`}
                          page={page}
                          fontSize="md"
                          color="white"
                          _hover={{
                            bg: "orange.500",
                          }}
                          _current={{
                            bg: "perla.500",
                            fontSize: "sm",
                            w: [8, 8, 12, 12],
                          }}
                        />
                      ))}
                    </PaginationPageGroup>
                    <PaginationNext
                      _hover={{
                        bg: "orange.500",
                      }}
                      bg="perla.700"
                      color="white"
                    >
                      {windowSize.innerWidth < 720 
                        ? <Icon as={MdOutlineArrowForwardIos}/>
                        : <Text>Avanti</Text>
                      }
                    </PaginationNext>
                  </PaginationContainer>
                </Pagination>

                {/* <Center>
                  <Text fontSize={20}>Numero di video per pagina: </Text>
                  <Select ml={2} onChange={handlePageSizeChange} w={36}>
                    <option value="12">12</option>
                    <option value="24">24</option>
                    <option value="48">48</option>
                  </Select>
                </Center> */}

                <SimpleGrid 
                  columns={[1, 1, 2, 2, 3]} 
                  spacingX={4} 
                  spacingY={6} 
                  p={4}
                >
                  {videosByCategory.data.map((video: StrapiVideoResponse) => {
                    const categoryName = video.attributes.Category.data.attributes.Name as string;
                    return (
                      <VideoBox 
                        key={`video-${categoryName.toLocaleLowerCase()}-${video.attributes.Name.toLocaleLowerCase()}`}
                        categoryName={categoryName}
                        video={video}
                        userOrder={order!}
                        totalVideosEnabled={videosEnabledByUser.meta.pagination.total}
                      />
                    )})
                  }
                </SimpleGrid>

              </Stack>
            }
          </>
        } 
      </Stack>
    )
  );
}