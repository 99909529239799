export enum PlanType {
  Bronze = "Bronze",
  Silver = "Silver",
  Gold = "Gold",
  None = "Nessuno"
}

export const getPlanType = (productId?: string) => {
    
    if (!productId) return PlanType.None;

    switch(productId)
    {
      case process.env.REACT_APP_PRODUCT_BRONZE:
        return PlanType.Bronze;
      case process.env.REACT_APP_PRODUCT_SILVER:
        return PlanType.Silver;
      case process.env.REACT_APP_PRODUCT_GOLD:
        return PlanType.Gold;
      default:
        return PlanType.None;
    }
  } 